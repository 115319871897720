export default {
  'button.cancel': 'Cancel',
  'button.save': 'Save',
  'error.internal': 'Internal server error. Please try again later.',
  'label.UID': 'UID',
  'label.add': 'Add',
  'label.addDict': 'dict table',
  'label.address': 'Address',
  'label.agentEmployeeId': 'Delegator UID',
  'label.authorizer': 'Authorizer UID',
  'label.authorizerName': 'Authorizer  Name',
  'label.cancel': 'Cancel',
  'label.code': 'Code',
  'label.content': 'Content',
  'label.createTime': 'Create Time',
  'label.dataPermissions': 'Data Permissions',
  'label.delegation': 'Delegation',
  'label.delegationName': 'Delegator Name',
  'label.delete': 'Delete',
  'label.detail': 'Detail',
  'label.edit': 'Edit',
  'label.email': 'Email',
  'label.endDate': 'End Date',
  'label.Date': 'Date',
  'label.endTime': 'End Time',
  'label.item': 'Item',
  'label.name': 'Name',
  'label.no': 'No',
  'label.notification.content': 'Content',
  'label.notification.content.placeholder': 'Searching content...',
  'label.notification.handle': 'Link',
  'label.notification.ignore': 'Mark read',
  'label.notification.read': 'Read',
  'label.notification.setRead': 'Read',
  'label.notification.title': 'Title',
  'label.notification.title.placeholder': 'Searching title...',
  'label.notification.unread': 'Unread',
  'label.operation': 'Action',
  'label.permission.action': 'Permission Action',
  'label.permission.code': 'Permission Code',
  'label.permission.description': 'Permission Description',
  'label.permission.name': 'Permission Name',
  'label.permission.type': 'Permission Type',
  'label.permission.module': 'Permission Module',
  'label.permissions': 'Permissions',
  'label.mobile': 'Mobile',
  'label.remark': 'Remark',
  'label.reset': 'Reset',
  'label.role.code': 'Role Code',
  'label.role.description': 'Role Description',
  'label.role.isGlobal': 'Global Role',
  'label.role.name': 'Role Name',
  'label.roles': 'Roles',
  'label.save': 'Save',
  'label.search': 'Search',
  'label.space': ' ',
  'label.startDate': 'Start Date',
  'label.startTime': 'Start Time',
  'label.status': 'Status',
  'label.submit': 'Submit',
  'label.synchronize': 'Synchronize',
  'label.total': 'Total',
  'label.type': 'Type',
  'label.user.approval-status': 'Approval Staus',
  'label.user.type': 'User Type',
  'label.username': 'Username',
  'label.yes': 'Yes',
  'label.endpoint': 'Endpoint',
  'menu.application-management': 'Application Management',
  'menu.audit': 'Audit',
  'menu.coiReport': 'Coi Report',
  'menu.delegation': 'Delegation ',
  'menu.dictionary': 'Data Dictionary',
  'menu.Resource': 'Resource',
  'menu.Sourcing': 'Sourcing',
  'menu.domain': '领域',
  'menu.home': 'Home',
  'menu.homepage': 'Home Page',
  'menu.notFound': 'Not Found',
  'menu.rbac': 'System Management',
  'menu.rbac.permission': 'Permission',
  'menu.rbac.permission.data': 'Data Permission',
  'menu.rbac.permission.home': 'Permissions',
  'menu.rbac.role': 'Role',
  'menu.rbac.user': 'User',
  'menu.rbac.email': 'Email',
  'menu.rbac.user-approval': 'User Approval',
  'menu.system': 'System',
  'menu.system.dictionary': 'Data Dictionary',
  'menu.system.test': 'Demo',
  'menu.system.notification': 'Notification',
  'menu.rbac.department': 'Department',
  'menu.searchTitle': 'Query Conditions',
  'menu.menuTitle': 'Template List',
  'menu.menuTitle.package': 'Package List',
  'menu.menuTitle.signingRecord': 'Signing Record List',
  'menu.menuTitle.initiation': 'Initiation List',
  'message.backHome': 'Back Home',
  'message.delete.confirm': 'Are you sure to delete this record?',
  'message.delete.success': 'Delete record success.',
  'message.edit.sucess': 'Edit Successfully',
  'message.inputSearch': 'please input and click search',
  'message.noFavours': 'No Favourites',
  'message.noNotify': 'No Notify',
  'message.notfound': 'Sorry, the page you visited does not exist.',
  'message.notification.setRead': 'Are you sure to set this notification to read?',
  'message.permission.async': 'Are you sure to synchronize permissions?',
  'message.department.async': 'Are you sure to synchronize departments?',
  'message.user.async': 'Are you sure to synchronize users?',
  'message.permission.delete': 'Are you sure to delete this permission?',
  'message.role.delete': 'Are you sure to delete this role?',
  'message.save.success': 'Saved.',
  'message.set.success': 'Set Successfully',
  'message.user.delete': 'Are you sure to delete this user?',
  'notifier.clear': 'Clear All',
  'notifier.more': 'More',
  'rbac.role.title': 'Role',
  'rbac.user.title': 'User',
  'rule.empty': 'This field is empty.',
  'rule.endTime': 'endTime can not be empty!',
  'rule.format': 'This field format is wrong.',
  'rule.startTime': 'startTime can not be empty!',
  'rule.targetUserId': 'delegator uid can not be empty!',
  'label.table.edit': 'Modify',
  'label.table.delete': 'Delete',
  'label.table.permission': 'Permission',
  'label.import': 'Import',
  'label.export': 'Export',
  'department.add': 'Add',
  'department.edit': 'Edit',
  'department.detail': 'Detail',
  'department.delete': 'Delete',
  'department.unitId': 'UnitId',
  'department.unitName': 'UnitName',
  'department.shortName': 'ShortName',
  'department.parentId': 'ParentId',
  'department.placeholderUnitName': 'Please input UnitName',
  'department.placeholderUnitId': 'Please input UnitId',
  'department.placeholderShortName': 'Please input ShortName',
  'department.placeholderParentId': 'Please input ParentId',
  'department.submit': 'submit',
  'department.addDepartment': 'Add Department',
  'department.ruleUnitId': 'Please input UnitId',
  'department.ruleUnitName': 'Please input UnitName',
  'department.ruleShortName': 'Please input ShortName',
  'department.ruleParentId': 'Please input ParentId',
  'department.sureDelete': 'Are you sure delete',
  'department.yes': 'Yes',
  'department.cancel': 'Cancel',
  'department.deleteSuccess': 'Delete Success',
  'department.editDepartmentInfo': 'Edit Department',
  'department.detailDepartmentInfo': 'Detail Department',
  'department.addSuccess': 'Add Success',
  'department.updateSuccess': 'Update Success',
  // 测试页
  'menu.demo': 'Demo',
  'menu.demoTest': 'Demo View',
  'menu.docManagement': 'Doc Management',
  'menu.docManagement.template': 'Template Management',
  'menu.docManagement.package': 'Package Management',
  'menu.hrdsms': 'HRDSMS',
  'menu.docManagement.initiation': 'Initiation Record',
  'menu.report': 'Personnel Change',
  'menu.report.personnelChange': 'Personnel Change',
  'menu.COI': 'COI Record',
  'menu.todo': 'To-Do Task',
  'menu.COI.toDo': 'COI Record',
  'menu.reporting': 'Signing Record',
  'menu.reporting.sign': 'Signing Record',
  'menu.reporting.initiation': 'Initiation Record',
  'menu.signingRecord': 'Signing Record',
  'menu.signingRecord.record': 'Signing Record',
  'menu.signingRecord.initiation': 'Initiation Record',
  'initiation.selectOption.sign':'Please make sure the selected employee and his/her Line Manager are both mainland Chinese nationals, otherwise please go through offline hard coy signature process.  请确保员工及其直线经理都为大陆籍，否则请签署纸质文件。'

};
